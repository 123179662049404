<template>
  <div id="app">
    <Nav />
    <main>
      <transition name="fade" mode="out-in">
        <router-view/> 
      </transition>
    </main>
  </div>
</template>

<script>
import Nav from './components/Nav.vue'

export default {
  name: 'App',
  components: {
    Nav
  }
}
</script>

<style>

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity .2s ease;
}

.fade-leave-active {
  transition: opacity .2s ease;
  opacity: 0;
}


@font-face {
  font-family: "Neogrotesk";
  font-weight: 200;
  src: url('./assets/fonts/NeogroteskEss-Light.ttf') format("truetype"),
  url('./assets/fonts/NeogroteskEss-Light.woff') format("woff"),
  url('./assets/fonts/NeogroteskEss-Light.svg') format("svg");
}

@font-face {
  font-family: "Neogrotesk";
  font-weight: 700;
  src: url('./assets/fonts/NeogroteskEss-Bold.ttf') format("truetype"),
  url('./assets/fonts/NeogroteskEss-Bold.woff') format("woff"),
  url('./assets/fonts/NeogroteskEss-Bold.svg') format("svg");
}

html, body{
  padding: 0;
  margin: 0;
  background-color: #FBCBC1;
  overflow-x: hidden;
  scroll-behavior: smooth;
  /* cursor: none; */
}

#app {
  font-family: "Neogrotesk", Helvetica, Arial, sans-serif;
  font-weight: 200;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #381722;
}

*{
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0);
}

::-moz-selection,
::selection {
  color: #381722;
  background-color: #EEBFB7;
}

a{
  text-decoration: none;
  color: inherit;
}

h1, h2{
  font-weight: 700;
  text-transform: none;
}

h1{
  font-size: 70px
}

h3, h4{
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 2px;
}

.cursor{
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor.inner{
  height: 12px;
  width: 12px;
  background: #EEBFB7;
}

.cursor.outer{
  height: 70px;
  width: 70px;
  border: 1px solid #381722;
}

/* animation */

.fade-in{
  opacity: 0;
  transform: translateY(50px);
}

.visible.fade-in,
.visible .fade-in{
  animation: .5s fade-in .3s forwards;
}

@keyframes fade-in{
  from{
    opacity: 0;
    transform: translateY(50px);
  }
  to{
    opacity: 1;
    transform: translateY(0);
  }
}


/* responsive */
@media only screen and (min-width: 1800px) {
  h1{
    font-size: 100px;
  }
}
@media only screen and (max-width: 768px) {
  h1{
    font-size: 50px;
  }
}



</style>
