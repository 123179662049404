<template>
  <div class="project_single project_wrapper">
    <div class="title" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'top'), once: true }" :class="{ 'visible': showAnimationFor.top, 'invisible': !showAnimationFor.top }">
      <h4>Webdesign & Entwicklung</h4>
      <h3>Frauengemeinschft Widnau</h3>
    </div>
    <div class="content">
      <div class="inner">
        <div class="img_wrapper main" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'main_img'), once: true }" :class="{ 'visible': showAnimationFor.main_img, 'invisible': !showAnimationFor.main_img }">
          <img class="main_img" src="@/assets/projects/fmgw/mo_project_fmgw_01.jpeg" alt="">
        </div>
        <div class="text_wrapper" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'text'), once: true }" :class="{ 'visible': showAnimationFor.text, 'invisible': !showAnimationFor.text }">
          <h4>Webdesign & Entwicklung</h4>
          <p>Die Frauengemeinschaft Widnau bietet zahlreiche Anlässe für Frauen und Kinder in der Gemeinde. Im Sommer 2020 habe ich in enger Zusammenarbeit mit dem Vorstand, die neue Website gestaltet und entwickelt. Das CMS WordPress erlaubt den Redakteuren eine einfache und selbständige Bearbeitung aller Inhalte auf der Website. <a href="https://fmgwidnau.ch/" target="_blank">www.fmgwidnau.ch</a></p>
        </div>
        <div class="img_wrapper more">
          <div v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'a'), once: true }" :class="{ 'visible': showAnimationFor.a, 'invisible': !showAnimationFor.a }">
            <img class="project_img" src="@/assets/projects/fmgw/mo_project_fmgw_02.jpeg" alt="">
          </div>
          <div v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'b'), once: true }" :class="{ 'visible': showAnimationFor.b, 'invisible': !showAnimationFor.b }">
            <img class="project_img" src="@/assets/projects/fmgw/mo_project_fmgw_03.jpeg" alt="">
          </div>
        </div>
        <div class="next_wrapper" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'next'), once: true }" :class="{ 'visible': showAnimationFor.next, 'invisible': !showAnimationFor.next }">
          <router-link to="/blauregen">
            weiter
          </router-link>
          <svg xmlns="http://www.w3.org/2000/svg" width="399.099" height="586.066" viewBox="0 0 399.099 586.066">
            <path id="Pfad_47" data-name="Pfad 47" d="M3311,2239l240,240-240,240" transform="translate(-3257.967 -2185.967)" fill="none" stroke="#eebfb7" stroke-width="150"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  },
  data() {
    return{
      showAnimationFor: {
        top: false,
        main_img: false,
        text: false,
        a: false,
        b: false,
        next: false
      } 
    }
  },
  methods: {
    inViewport(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    }
  },
  metaInfo: {
    title: 'Frauengemeinschaft Widnau | Moritz Züst | Design & Entwicklung',
    description: 'Webdesign & Entwicklung für die Frauengemeinschaft Widnau basierend auf WordPress.'
  }
}
</script>


<style scoped>
.title{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 4vh 5vw;
}
h3{
  /* left: 40px; */
  bottom: 0px;
  padding-left: 50px;
  margin: 5px 0;
}
h4{
  margin: 5px 0;
  display: flex;
  align-items: center;
}
h4::before{
  content: "";
  display: inline-block;
  height: 1px;
  width: 40px;
  background: #381722;
  margin-right: 10px;
}
.title h3,
.title h4,
.text_wrapper h4{
  position: relative;
}
.title h3::after,
.title h4::after,
.text_wrapper h4::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background: #381722;
  transition: .8s cubic-bezier(.75,.2,.1,1);
}
.visible.title h3::after,
.visible.title h4::after{
  width: 0;
}
.img_wrapper.main{
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.img_wrapper.main::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  background: #EEBFB7;
  transition: .8s cubic-bezier(.75,.2,.1,1) .5s;
}
.img_wrapper.main.visible::after{
  height: 0;
}
img{
  height: 100%;
  width: 100%;
  object-fit: cover;
	object-position: center;
	font-family: 'object-fit: cover; object-position: bottom;';
  transform: scale(1.1);
  transition: .8s cubic-bezier(.75,.2,.1,1) .5s;
}
.img_wrapper.main.visible img{
  transform: scale(1);
}
.text_wrapper{
  padding: 10vh 5vw 0 5vw;
}
.text_wrapper h4{
  position: relative;
  padding-left: 50px;
}
.text_wrapper h4::before{
  position: absolute;
  left: 0;
  top: 11px;
}
.text_wrapper p{
  text-transform: none;
  text-align: left;
  font-size: 1.1rem;
  letter-spacing: 1px;
  line-height: 2;
  padding-left: 50px;
}
.text_wrapper.visible h4::after{
  width: 0;
}
.img_wrapper.more{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5vh 5vw;
}
.img_wrapper.more div{
  height: 45vw;
  width: calc(50% - 2.5vw);
  margin: 2.5vw 0;
  position: relative;
  overflow: hidden;
}
.img_wrapper.more div::after{
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: #EEBFB7;
  transition: .8s cubic-bezier(.75,.2,.1,1) .2s;
}
.img_wrapper.more div.visible::after{
  height: 0;
}
.img_wrapper.more .visible>img{
  transform: scale(1);
}
.img_wrapper.more img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition-delay: .2s;
}
.next_wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.next_wrapper svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .3s;
}
.next_wrapper a{
  font-size: 60px;
  text-decoration: none;
  color: #381722;
  font-weight: 700;
  text-transform: none;
  z-index: 2;
  position: relative;
}
.next_wrapper a::after{
  content:"";
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #381722;
  transition: .5s cubic-bezier(.75,.2,.1,1) .5s;
}
.next_wrapper.visible a::after{
  width: 0;
}
#pfeil{
  stroke-dashoffset: 680;
  stroke-dasharray: 680;
  transition: 1s cubic-bezier(.75,.2,.1,1);
}
.visible #pfeil{
  stroke-dashoffset: 0;
  stroke-dasharray: 680;
}
.next_wrapper a:hover ~ svg{
  transform: translate(-50%, -50%) scale(.9);
}
/* responsive */
@media only screen and (max-width: 756px){
  .img_wrapper.main{
    height: 55vh;
  }
  .text_wrapper h4{
    text-align: left;
  }
  .img_wrapper.more div{
    width: 100%;
  }
}
</style>
