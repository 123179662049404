import Vue from 'vue'
import App from './App.vue'

/* Import Vue Router */
import VueRouter from 'vue-router'

/* Import routes */
import { routes } from './routes'

/* Registering the Vue Router */
Vue.use(VueRouter);

import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)


const router = new VueRouter({
  routes,
  mode: 'history'
});

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  // store,
  render: h => h(App)
})
