<template>
  <div class="wrapper">
    <div class="top_row">
      <div class="title_container" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'title'), once: true }" :class="{ 'visible': showAnimationFor.title, 'invisible': !showAnimationFor.title }">
        <h1>
          <span>Über</span>
          <br>
          <span>mich</span>
        </h1>
        <h3>& meine Ausbildung</h3>
      </div>
      <div class="img_container" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'img'), once: true }" :class="{ 'visible': showAnimationFor.img, 'invisible': !showAnimationFor.img }">
        <img src="../assets/img/moritz_hslu_portrait_web.jpeg" alt="portrait">
      </div>
    </div>
      <div class="cv_container">
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'a'), once: true }" :class="{ 'visible': showAnimationFor.a, 'invisible': !showAnimationFor.a }">
          <h3>SEIT FEB 2021</h3>
          <h2>Mediamatiker bei point break GmbH in St. Gallen</h2>
          <p>Zuständigkeitsbereiche: Webdesign, Frontend-Development, Projektmanagement</p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'b'), once: true }" :class="{ 'visible': showAnimationFor.b, 'invisible': !showAnimationFor.b }">
          <h3>SEP 2020</h3>
          <h2>Firmengründung blauregen klg (Mitgründer)</h2>
          <p>Startup im Bereich Branding Webdesign und -entwicklung.<br> 
          <a href="https://blauregen.ch/" target="_blank">www.blauregen.ch</a></p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'c'), once: true }" :class="{ 'visible': showAnimationFor.c, 'invisible': !showAnimationFor.c }">
          <h3>AUG 2020 – FEB 2021</h3>
          <h2>Zivildienst</h2>
          <p>Alters- und Pflegeheim Krone in Rehetobel <br>
            Betreuung & Aktivierung auf der Demenzabteilung</p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'd'), once: true }" :class="{ 'visible': showAnimationFor.d, 'invisible': !showAnimationFor.d }">
          <h3>JUL 2020</h3>
          <h2>Ausbildungsabschluss Mediamatiker EFZ mit BM</h2>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'e'), once: true }" :class="{ 'visible': showAnimationFor.e, 'invisible': !showAnimationFor.e }">
          <h3>2018 – 2020</h3>
          <h2>Praktikumsstelle und Ausbildungsabschluss zum Mediamatiker EFZ mit BM</h2>
          <p>point break GmbH in St. Gallen</p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'f'), once: true }" :class="{ 'visible': showAnimationFor.f, 'invisible': !showAnimationFor.f }">
          <h3>2016 – 2018</h3>
          <h2>Berufliche Grundausbildung zum Mediamatiker EFZ mit BM</h2>
          <p>SBW Neue Medien AG in Romanshorn</p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'g'), once: true }" :class="{ 'visible': showAnimationFor.g, 'invisible': !showAnimationFor.g }">
          <h3>2013 – 2016</h3>
          <h2>Sekundarschule in Heiden</h2>
          <p>Stammklasse E</p>
        </div>
        <div class="cv_item" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'h'), once: true }" :class="{ 'visible': showAnimationFor.h, 'invisible': !showAnimationFor.h }">
          <h3>2007 – 2013</h3>
          <h2>Primarschule in Heiden</h2>
        </div>
      </div>
      <div class="projects_container">
        <a href="/#projekte">Projekte</a>
      </div>
    
  </div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  },
  data() {
    return{
      showAnimationFor: {
        img: false,
        title: false,
        a: false,
        b: false,
        c: false,
        d: false,
        e: false,
        f: false,
        g: false,
        h: false,
      } 
    }
  },
  methods: {
    inViewport(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    }
  },
  metaInfo: {
    title: 'About | Moritz Züst | Design & Entwicklung',
    description: 'Hi, ich bin Moritz, Mediamatiker und hier erzähl ich dir mehr über meinen Werdegang.'
  }

}
</script>

<style scoped>
.wrapper{
  padding: 10vh 10vw;
}

.top_row{
  display: flex;
  justify-content: space-between;
}

.title_container{
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: 10vh;
}

.title_container h1{
  line-height: 1.1;
}

.title_container::after{
  content: "";
  position: absolute;
  height: 85px;
  width: 85px;
  background: #FBCBC1;
  top: -5px;
  left: 95px;
  z-index: 2;
}

.title_container::before{
  content: "";
  position: absolute;
  top: -88px;
  left: 100px;
  height: 60px;
  width: 60px;
  border: 50px solid #EEBFB7;
  border-radius: 50%;
  z-index: 1;
}

.title_container h1,
.title_container h3{
  margin: 0;
  z-index: 3;
}

.title_container h1 span:last-child{
  color: #EEBFB7;
}

.title_container span,
.title_container h3{
  position: relative;
}


.title_container span::after,
.title_container h3::after{
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  right: 0;
  top: 0;
  background: #381722;
  transition: .8s cubic-bezier(.75,.2,.1,1) .5s;
}

.title_container.visible span::after,
.title_container.visible h3::after{
  width: 0;
}

.img_container{
  height: 350px;
  width: 250px;
  margin: 10vh 5vw 0 0;
  border: 50px solid #EEBFB7;
  position: relative;
  overflow: hidden;
}

.img_container::after{
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #EEBFB7;
  transition: .8s cubic-bezier(.75,.2,.1,1) .2s;
}

.img_container.visible::after{
  height: 0;
}

.img_container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform: scale(1.1);
  transition: .8s cubic-bezier(.75,.2,.1,1) .4s;
}

.img_container.visible img{
  transform: scale(1);
}

.cv_container{
  display: flex;
  flex-direction: column;
  border-left: 50px solid #EEBFB7;
  padding: 50px;
}

.cv_container .cv_item{
  text-align: left;
  margin: 5vh 0;
}

.cv_container .cv_item h3{
  position: relative;
}

.cv_container .cv_item h3::after{
  content: "";
  position: absolute;
  height: 1px;
  width: 50px;
  left: -75px;
  top: 50%;
  transform: translateY(-50%);
  background: #381722;
}

.cv_container .cv_item h2{
  margin: 0;
}

.cv_container .cv_item p{
  text-transform: none;
  font-size: 20px;
}

.cv_item h3,
.cv_item h2,
.cv_item p{
  transform: translateY(10px);
  opacity: 0;
  transition: .5s cubic-bezier(.75,.2,.1,1) .4s;
}

.cv_item.visible h3,
.cv_item.visible h2,
.cv_item.visible p{
  transform: translateY(0);
  opacity: 1;
}

.projects_container{
  margin: 40vh 0 20vh 0;
  padding: 10vh 0;
}

.projects_container a{
  text-decoration: none;
  color: #381722;
  position: relative;
  text-transform: none;
  font-weight: 700;
  font-size: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projects_container a::after{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30vh;
  width: 30vh;
  border-radius: 50%;
  border: 150px solid #EEBFB7;
  mix-blend-mode: darken;
  transition: .2s;
  max-width: 100vw;
}

.projects_container a:hover:after {
  transform: translate(-50%, -50%) scale(0.8);
}

/* responsive */
@media only screen and (min-width: 1800px){
  .img_container{
    height: 450px;
    width: 350px;
  }
}

@media only screen and (max-width: 756px){
  .top_row{
    flex-direction: column-reverse;
  }
  .title_container{
    margin: 15vh 0
  }
  .title_container::before{
    top: -78px;
    left: 70px;
    border: 35px solid #EEBFB7;
  }
  .title_container::after{
    height: 80px;
    width: 80px;
    top: -15px;
    left: 60px;
  }
  .img_container{
    margin: 0;
    width: calc(100% - 24vw);
    height: 350px;
  }
  .projects_container a{
    font-size: 60px
  }
}

</style>
