<template>
  <div class="nav_wrapper">
    <div class="icon">
      <div></div>
      <div></div>
      <div></div>
    </div>
    <div class="nav">
      <div class="container">
        <ul>
          <li>
            <a href="/#home">
              Home
            </a>
          </li>
          <li>
            <a href="/#projekte">
              Projekte
            </a>
          </li>
          <li>
            <router-link to="/about">
              Über mich
            </router-link>
          </li>
          <li>
            <a href="/#kontakt">
              Kontakt
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import JQuery from 'jquery'
let $ = JQuery

export default {
  mounted() {
    $('.icon, li').click(function(){
      $('.nav_wrapper').toggleClass('active');
    });
  }
}
</script>

<style scoped>
.nav{
  position: fixed;
  height: 100vh;
  width: 100vw;
  background-color: #FBCBC1;
  display: flex;
  z-index: 9;
  transition: .3s;
  transform: translateX(-100%);
}
.nav_wrapper.active .nav{
  transform: translateX(0);
}
.container{
  height: 100%;
  width: 100%;
}
ul{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20vw;
  margin: 0;
}
ul li{
  list-style-type: none;
  text-align: left;
  padding: 3vh 0;
}
ul li a{
  position: relative;
  text-decoration: none;
  color: #EEBFB7;
  text-transform: none;
  font-size: 70px;
  font-weight: 700;
  transition: .3s;
}
ul li a:hover{
  color: #381722;
}
ul li a::before{
  content: "";
  position: absolute;
  height: 40%;
  left: 0;
  bottom: 0;
  width: 0;
  background: #EEBFB7;
  transition: .3s;
  mix-blend-mode: darken;
}
ul li a:hover::before{
  width: 100%;
}
.icon{
  display: flex;
  height: 50px;
  width: 50px;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
  position: fixed;
  top: 50%;
  left: 50px;
  transform: translateY(-50%);
  z-index: 99;
}
.icon:after{
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  height: 40px;
  width: 40px;
  border-radius: 100px;
  border: 25px solid #EEBFB7;
  transition: .2s;
  mix-blend-mode: darken;
}
.icon:hover:after{
  transform: translate(-50%, -50%) scale(1);
}
.icon>div{
  height: 1px;
  width: 50px;
  background-color: #381722;
  opacity: 1;
  transition: .3s;
}
.nav_wrapper.active .icon>div:first-child{
  transform: translate(0, 25px) rotate(45deg);
}
.nav_wrapper.active .icon>div:nth-child(2){
  transform: translateX(-100%);
  opacity: 0;
}
.nav_wrapper.active .icon>div:last-child{
  transform: translate(0, -25px) rotate(-45deg);
}

/* responsive */
@media only screen and (max-width: 768px){
  .icon{
    left: 5vw;
    height: 30px;
    width: 30px;
  }
  .icon>div{
    width: 30px;
  }
  .icon:after{
    display: none;
  }
  .nav_wrapper.active .icon>div:first-child{
  transform: translate(0, 15px) rotate(45deg);
  }
  .nav_wrapper.active .icon>div:last-child{
    transform: translate(0, -15px) rotate(-45deg);
  }
  ul li a{
    font-size: 50px
  }
}


</style>
