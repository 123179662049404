<template>
  <div class="wrapper">
    <div class="top" id="home" v-observe-visibility="{ callback: isVisible => inViewport(isVisible, entry, 'top'), once: true }" :class="{ 'visible': showAnimationFor.top, 'invisible': !showAnimationFor.top }">
      
      <div class="svg_wrapper">
        <svg xmlns="http://www.w3.org/2000/svg" width="660" height="660" viewBox="0 0 660 660">
          <g id="circle-top" data-name="Ellipse 29" fill="none" stroke="#eebfb7" stroke-width="150">
            <circle cx="330" cy="330" r="330" stroke="none"/>
            <circle cx="330" cy="330" r="255" fill="none"/>
          </g>
        </svg>
      </div>

      <div class="img-container left fade-in">
        <img src="@/assets/projects/tidora/mo_project_tidora_03.jpeg" alt="image 01">
        <div class="img-cover"></div>
      </div>
      <div class="img-container right fade-in">
        <img src="@/assets/projects/26beer/mo_project_26beer_02.jpeg" alt="image 02">
        <div class="img-cover"></div>
      </div>

      <div class="text_container fade-in">
        <h1>
          <span>Design</span>
          <br>
          <span>& Entwicklung</span>
        </h1>
        <div class="about_link">
          <span>Moritz Züst</span>
          <span><router-link to="/about">Über mich</router-link></span>
        </div>
      </div>

    </div>
    <div class="about fade-in" v-observe-visibility="{ callback: isVisible => inViewport(isVisible, entry, 'about'), once: true }" :class="{ 'visible': showAnimationFor.about, 'invisible': !showAnimationFor.about }">
      <h2>
        Hi, ich bin Moritz, ein ausgebildeter Mediamatiker mit dem Fokus in Design und Webentwicklung.
      </h2>
      <router-link to="/about" class="more_link_about">Mehr</router-link>
    </div>
    <div class="projects" id="projekte">

      <router-link to="/26-beer" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'proj01'), once: true }" :class="{ 'visible': showAnimationFor.proj01, 'invisible': !showAnimationFor.proj01 }">
        <div class="project_preview project_wrapper">
          <h4>Design & Packaging</h4>
          <h3>26 Beer</h3>
          <div class="img_wrapper">
            <img class="main_img" src="@/assets/projects/26beer/mo_project_26beer_01_l.jpeg" alt="">
          </div>
        </div>
      </router-link>  

      <router-link to="/fmgw" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'proj02'), once: true }" :class="{ 'visible': showAnimationFor.proj02, 'invisible': !showAnimationFor.proj02 }">
        <div class="project_preview project_wrapper">
          <h4>Webdesign & Entwicklung</h4>
          <h3>Frauengemeinschft Widnau</h3>
          <div class="img_wrapper">
            <img class="main_img" src="@/assets/projects/fmgw/mo_project_fmgw_01.jpeg" alt="">
          </div>
        </div>
      </router-link>      

      <router-link to="/blauregen" v-observe-visibility="{ callback: (isVisible, entry) => inViewport(isVisible, entry, 'proj03'), once: true }" :class="{ 'visible': showAnimationFor.proj03, 'invisible': !showAnimationFor.proj03 }">
        <div class="project_preview project_wrapper">
          <h4>Branding & Webdesign</h4>
          <h3>blauregen</h3>
          <div class="img_wrapper">
            <img class="main_img" src="@/assets/projects/blauregen/mo_project_blauregen_01.jpeg" alt="">
          </div>
        </div>
      </router-link>      

    </div>
    <div class="contact" id="kontakt" v-observe-visibility="{ callback: isVisible => inViewport(isVisible, entry, 'contact'), once: true }" :class="{ 'visible': showAnimationFor.contact, 'invisible': !showAnimationFor.contact }">
      <a href="mailto:hallo@moritzzuest.ch">hallo@moritzzuest.ch</a>
    </div>
  </div>
</template>

<script>

// import JQuery from 'jquery'
// let $ = JQuery

export default {
  data() {
    return{
      showAnimationFor: {
        top: false,
        about: false,
        proj01: false,
        proj02: false,
        proj03: false,
        contact: false,
      } 
    }
  },
  methods: {
    inViewport(isVisible, entry, section) {
      this.showAnimationFor[section] = isVisible;
    }
  },
  metaInfo: {
    title: 'Moritz Züst | Design & Entwicklung',
    description: 'Hi, ich bin Moritz, ein ausgebildeter Mediamatiker mit dem Fokus in Design und Webentwicklung.'
  }
}
</script>

<style scoped>

.top.visible .fade-in{
  animation: .5s fade-in 1s forwards;
}


.top {
  padding: 10vh 10vw;
  position: relative;
  height: 101vh;
  box-sizing: border-box;
}

h1{
  text-align: left;
  line-height: 1.05;
  overflow: hidden;
}

h1 span{
  transform: translateY(500px);
}

.svg_wrapper{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.img-container {
  position: absolute;
  overflow: hidden;
}

.left{
  height: 45vh;
  width: 25vw;
  left: 15vw;
  max-width: 400px;
}

.right{
  height: 50vh;
  width: 30vw;
  right: 10vw;
  top: 40vh;
  max-width: 450px;
}

.img-container img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  position: relative;
  transform: scale(1.1);
  transition: .8s ease-in-out;
}

.img-container .img-cover{
  position: absolute;
  background: #EEBFB7;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transition: .8s cubic-bezier(.75,.2,.1,1);
  transition-delay: 1s;
}

.top.visible .img-cover{
  height: 0;
}

.top.visible .right .img-cover{
  transition-delay: 1.2s;
}

.top.visible .img-container img{
  transform: scale(1);
}

.text_container{
  position: absolute;
  bottom: 10vh;
}

.text_container span{
  position: relative;
}

.text_container span::after{
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: #381722;
  right: 0;
  top: 0;
  bottom: 0;
  transition: 0.8s cubic-bezier(.75,.2,.1,1);
  transition-delay: 1.5s;
}

.top.visible .text_container span::after{
  width: 0;
}


svg{
  max-width: 80vw;
  height: auto;
}

#circle-top{
  stroke-dashoffset: 1602;
  stroke-dasharray: 1602;
  transition: 1s cubic-bezier(.75,.2,.1,1);
}

.top.visible #circle-top{
  stroke-dashoffset: 0;
  stroke-dasharray: 1602;
}

.about_link {
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: 2px;
  overflow: hidden;
  width: 200px;
  height: 20px;
  cursor: pointer;
}

.about_link::before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 30px;
  background: #381722;
  margin-right: 10px;
}

.about_link>span {
  position: absolute;
  top: 0;
  left: 40px;
  text-align: left;
  white-space: nowrap;
  transition: .5s;
}

.about_link>span:last-child {
  top: 100%;
}

.about_link:hover>span:first-child {
  top: -100%;
}

.about_link:hover>span:last-child {
  top: 0;
}

.about {
  padding: 20vh 20vw 5vh 20vw;
}

h2 {
  font-size: 50px;
  font-weight: 100;
}

.about h2 {
  position: relative;
}

.about h2:before {
  content: "";
  position: absolute;
  top: -15vh;
  left: 50%;
  width: 1px;
  height: 10vh;
  background-color: #381722;
}

.more_link_about {
  position: relative;
}

.more_link_about::after {
  content: "";
  position: absolute;
  height: 50%;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: #EEBFB7;
  transition: .3s;
  mix-blend-mode: darken;
}

.more_link_about:hover::after {
  width: 100%;
}

/* Project Preview */

.project_preview {
  height: 80vh;
  width: 100vw;
  margin: 10vh 0 10vh 20vw;
  padding: 50px 40px;
  position: relative;
}

.project_preview:hover .main_img{
  transform: scale(1.02);
}

h3,
h4 {
  position: absolute;
  padding: 15px 0;
  margin: 0;
  color: #381722;
}

h3 {
  left: 40px;
  bottom: -5px;
}

h4 {
  left: 15px;
  bottom: 25px;
  transform: rotate(270deg);
  display: flex;
  align-items: center;
  transform-origin: center left;
}

h4::before {
  content: "";
  display: inline-block;
  height: 1px;
  width: 40px;
  background: #381722;
  margin-right: 10px;
}

.inner_wrapper {
  height: 100%;
  width: 100%;
  background: white;
  overflow-x: scroll;
}

.inner_wrapper:hover .main_img {
  transform: scale(1.05);
}

.img_wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.img_wrapper::after{
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #EEBFB7;
  z-index: 5;
  transition: .8s cubic-bezier(.75,.2,.1,1);
  transition-delay: .5s;
}

.main_img {
  height: 100%;
  width: 100%;
  transition: .5s cubic-bezier(.75,.2,.1,1);
  object-fit: cover;
  object-position: center;
  font-family: 'object-fit: cover; object-position: bottom;';
}

.visible .img_wrapper::after{
  width: 0;
}

/* Contact */
.contact {
  height: 50vh;
  margin: 25vh 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact a {
  text-decoration: none;
  color: #381722;
  position: relative;
  text-transform: none;
  font-weight: 700;
  font-size: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact a:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 30vh;
  width: 30vh;
  border-radius: 50%;
  border: 150px solid #EEBFB7;
  mix-blend-mode: darken;
  transition: .2s;
}

.contact a:hover:before {
  transform: translate(-50%, -50%) scale(0.8);
}

/* responsive */
@media only screen and (min-width: 1800px) {
  .about_link{
    height: 27px;
    width: 211px;
  }
  .about_link span{
    font-size: 22px;
  }
  .left{
    left: 25vw;
  }

  .right{
    right: 22vw;
  }
}

@media only screen and (max-width: 756px) {
  .left{
    top: 0;
    left: 5vw;
    width: 55vw;
  }
  .right{
    top: 50vh;
    right: 0;
    height: 30vh;
    width: 60vw;
  }
  .text_container{
    bottom: 5vh
  }
  .about{
    padding: 20vh 10vw 5vh 10vw;
  }
  .about h2{
    font-size: 35px;
  }
  .project_preview{
    height: 70vh;
  }
  .contact a{
    font-size: 35px;
  }
}

</style>
