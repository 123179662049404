import Home from './components/Home.vue'
import About from './components/About.vue'
import Twentysixbeer from './components/projects/Twentysixbeer.vue'
import Fmgw from './components/projects/Fmgw.vue'
import Blauregen from './components/projects/Blauregen.vue'





export const routes = [
  { path: '', component: Home },
  { path: '/about', component: About },
  { path: '/26-beer', component: Twentysixbeer },
  { path: '/fmgw', component: Fmgw },
  { path: '/blauregen', component: Blauregen },
]